import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import { Link } from 'gatsby'
import errorImg from '../assets/images/error.jpg'

const NotFoundPage = () => (
  <Layout>
    <Seo title='Error Page' />

    <div className='error-area'>
      <div className='d-table'>
        <div className='d-table-cell'>
          <div className='error-content'>
            <img
              src={errorImg}
              alt='Error'
            />
            <h3>Oops! Page Not Found</h3>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <Link to='/' className='default-btn btn-bg-two'>
              Return To Home Page
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
